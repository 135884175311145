import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Layout from 'layout/Layout';
import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Modal from 'react-bootstrap/Modal';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Image from 'common/Image';
import Seo from 'common/Seo';
import PageSchema from 'common/PageSchema';
import { getUrl, getTarget } from '../../utils/browser';
import { ProductProps } from './models';
import 'styles/main.scss';
import './Product.scss';

const Product: FC<{ data: ProductProps }> = ({
  data: {
    product: {
      image,
      warning,
      faqs,
      nutritional,
      nutritionalHeader,
      buy: [buy],
      seoMetaTitle,
      seoMetaKeywords,
      seoMetaDescription,
    },
  },
}) => {
  const [show, setShow] = React.useState(false);
  const hideNutritional = () => setShow(false);
  const showNutritional = () => setShow(true);

  return (
    <Layout headerTransparent>
      <Seo
        {...{ title: seoMetaTitle, description: seoMetaDescription, keywords: seoMetaKeywords }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      <div id="product">
        <section id="product__banner" className="text-center pt-5">
          <Image imageData={image} alt={image.altText} />
        </section>
        <section id="product__middle">
          <div className="container-fluid">
            <div className="row">
              <div className="col py-2 mt-5">
                <div className="mx-md-5 px-2">
                  <h4 className="text-uppercase text-center py-2 px-2 px-md-5 mx-md-5 mb-4">
                    {warning}
                  </h4>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="faqs pb-5 mb-5">
                  <Accordion>
                    {faqs.map(({ title, body }, index) => (
                      <AccordionItem key={index}>
                        <AccordionItemHeading>
                          <AccordionItemButton>
                            <DangerouslySetInnerHtml html={title} element="div" />
                          </AccordionItemButton>
                        </AccordionItemHeading>
                        <AccordionItemPanel>
                          <DangerouslySetInnerHtml html={body} element="div" className="mb-0" />
                        </AccordionItemPanel>
                      </AccordionItem>
                    ))}
                  </Accordion>
                  <div className="accordion__item">
                    <div className="accordion__button" aria-disabled="false" aria-expanded="false">
                      <button
                        className="bg-transparent border-0 text-uppercase w-100"
                        type="button"
                        onClick={showNutritional}
                      >
                        {nutritionalHeader}
                      </button>
                    </div>
                  </div>
                  <div className="accordion__button" aria-disabled="false" aria-expanded="false">
                    <div>
                      <a
                        href={getUrl(buy)}
                        target={getTarget(buy)}
                        className="w-100 d-block text-decoration-none"
                      >
                        {buy.name}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal show={show} onHide={hideNutritional}>
        <Modal.Dialog className="my-0">
          <Modal.Header closeButton />
          <Modal.Body className=" border-0">
            <img src={nutritional.fallbackUrl} alt={nutritional.altText} className="img-fluid" />
          </Modal.Body>
        </Modal.Dialog>
      </Modal>
    </Layout>
  );
};

export const query = graphql`
  query($link: String) {
    product(link: { eq: $link }) {
      title
      seoMetaTitle
      seoMetaKeywords
      seoMetaDescription
      warning
      image {
        fallbackUrl
        altText
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 800)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 800)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 800)
          }
        }
      }
      faqs {
        title
        body
      }
      nutritionalHeader
      nutritional {
        fallbackUrl
        altText
        desktop {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 800)
          }
        }
        mobile {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 800)
          }
        }
        tablet {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, width: 800)
          }
        }
      }
      buy {
        name
        queryString
        url
        target
      }
    }
  }
`;

export default Product;
